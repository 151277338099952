import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import BlogMasthead from '../components/BlogMasthead';
import ConnectSection from '../components/ConnectSection';
import Footer from '../components/Footer';
import Partners from '../components/Partners';
import Navbar from '../components/Navbar';
import BlogPreview from '../components/BlogPreview';
import '../styles/BlogListTemplate.scss';

const BlogListTemplate = (props) => {
	return (
		<Layout>
			<Navbar />
			<BlogMasthead />
			<section className="blog-list">
				<div className="container">
					<div className="columns">
						<div className="column is-half is-two-thirds-desktop">
							{props.data.updates.edges.map((item, index) => (
								<div className="blog-wrap" key={index}>
									<BlogPreview
										img={item.node.frontmatter.thumbnail}
										title={item.node.frontmatter.title}
										date={item.node.frontmatter.date}
										snippet={item.node.excerpt}
										name={item.node.fields.slug}
									/>
								</div>
							))}
						</div>
						<div className="column is-half is-one-third-desktop">
							<aside className="sidebar">
								<h3 className="dollars">
									{props.data.amount.edges[0].node.frontmatter.amount}
								</h3>
								<h3>Raised to date!</h3>
								<p>
									"It means the world to us, especially my kids. Everybody talks
									about community - the YMCA lives it every day."
								</p>
								<p className="attribution">
									- Dawn,
									<br /> Y member and recipient of YMCA financial assistance
								</p>
								<Link to="/donate">
									<button className="y-button orange medium">DONATE NOW</button>
								</Link>
							</aside>
						</div>
					</div>
				</div>
			</section>
			<ConnectSection />
			<Partners />
			<Footer />
		</Layout>
	);
};

export default BlogListTemplate;
