import React from 'react';
import { graphql } from 'gatsby';
import BlogListTemplate from '../templates/BlogListTemplate';

const BlogListPage = ({ data }) => {
	return <BlogListTemplate data={data} />;
};

export default BlogListPage;

export const query = graphql`
	query {
		updates: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/updates/" } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						author
						date(formatString: "MMMM DD, YYYY")
						thumbnail
					}
					excerpt
				}
			}
		}
		amount: allMarkdownRemark(
			filter: { frontmatter: { slug: { eq: "amount-raised" } } }
		) {
			edges {
				node {
					frontmatter {
						amount
					}
				}
			}
		}
	}
`;
